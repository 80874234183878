export const PAGE_ROUTES = {
  home: "/",
  // auth 관련
  resetPasswordVerify: "/auth/reset-password-verify",
  resetPassword: "/auth/reset-password",
  resetPasswordSuccess: "/auth/reset-password-success",

  dashboard: {
    // RE100 이행
    re100Management: "/dashboard/re100-management",
    // 계약 관리
    contractManagement: "/dashboard/contract-management",
    contractManagementDetail: "/dashboard/contract-management/detail",
    // 전력 사용내역
    powerUsageHistory: "/dashboard/power-usage-history",
    powerUsageHistoryDetail: "/dashboard/power-usage-history/detail",
    // 재생에너지 구매
    buyingRenewableEnergy: "/dashboard/buying-renewable-energy",

    // 계정 설정
    accountSetting: "/dashboard/account-setting",
  },
  notFound: "/not-found",
};

export const PAGE_TITLE_MAP = {
  [PAGE_ROUTES.dashboard.accountSetting]: "계정 설정",
  [PAGE_ROUTES.dashboard.contractManagement]: "계약 이행 현황",
  [PAGE_ROUTES.dashboard.re100Management]: "RE100 이행",
  [PAGE_ROUTES.dashboard.powerUsageHistory]: "전력 사용내역",
  [PAGE_ROUTES.dashboard.buyingRenewableEnergy]: "재생에너지 구매",
};
