import { Button } from "@60hz-io/shds";
import Link from "next/link";
import { MouseEventHandler } from "react";
import Image from "next/image";
import { PAGE_ROUTES } from "@/constants/pageRoute.constant";
import logoImg from "../../../../assets/images/login-logo.svg";

interface NotFoundErrorProps {
  onClickGoBack?: MouseEventHandler;
}

export function NotFoundError({ onClickGoBack }: NotFoundErrorProps) {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <Image src={logoImg} alt="goodnews RE100 logo" width={204} height={28} />
      {/* 디자인시스템 v1.0.0에 typography `h32-semibold`가 없음 */}
      <p className="text-text-default text-[32px] font-semibold mt-[36px]">페이지를 찾을 수 없습니다</p>
      <p className="p16-regular text-text-subtler mt-[16px] whitespace-pre-wrap text-center">{`페이지가 존재하지 않거나, 해당 페이지에 접근할 수 있는 권한이 없습니다.\n입력하신 주소가 정확한 지 다시 한 번 확인해 주세요.`}</p>
      <div className="mt-[48px] flex justify-center gap-[12px]">
        <Button size="big" appearance="outlined" onClick={onClickGoBack}>
          돌아가기
        </Button>
        <Link href={PAGE_ROUTES.dashboard.contractManagement}>
          <Button size="big" appearance="primary">
            홈으로 이동
          </Button>
        </Link>
      </div>
    </div>
  );
}
